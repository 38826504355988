// Types
import { BaseCaption, Caption } from '@supermeme-ai/types'

// External
import axios from 'axios'

export const saveMemeResult = async (
  accessToken: string,
  resultId: number,
  captions: Caption[],
  header?: BaseCaption,
  footer?: BaseCaption
) => {
  await axios.patch(
    `/api/result/${resultId}`,
    {
      captions,
      header,
      footer,
    },
    {
      headers: {
        token: accessToken,
      },
    }
  )
}
