import React from 'react'

type DefaultColorBlocksProps = {
  onClick: (color: string) => void
}

const DefaultColorBlocks: React.FC<DefaultColorBlocksProps> = ({ onClick }) => {
  return (
    <div className='grid w-full grid-cols-3 gap-2'>
      <button
        onClick={() => onClick('#FFFFFF')}
        style={{ backgroundColor: '#FFFFFF' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#545454')}
        style={{ backgroundColor: '#545454' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#000000')}
        style={{ backgroundColor: '#000000' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#c1ff72')}
        style={{ backgroundColor: '#c1ff72' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#7ed957')}
        style={{ backgroundColor: '#7ed957' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#00bf63')}
        style={{ backgroundColor: '#00bf63' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#FF3131')}
        style={{ backgroundColor: '#FF3131' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#5271ff')}
        style={{ backgroundColor: '#5271ff' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>

      <button
        onClick={() => onClick('#ffff00')}
        style={{ backgroundColor: '#ffff00' }}
        className='h-10 w-10 rounded-md border border-gray-400'
      ></button>
    </div>
  )
}

export default DefaultColorBlocks
