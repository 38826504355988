// React
import React, { useCallback, useState } from 'react'

// Components
import { Button } from '@/components/ui/button'

// Utils
import { useSession } from '@/utils/auth/user-context'

// External
import axios from 'axios'
import toast from 'react-hot-toast'
import { Loader2, Star } from 'lucide-react'

type StarButtonProps = {
  resultId: number
  callback: () => void
}

const StarButton: React.FC<StarButtonProps> = ({ resultId, callback }) => {
  const session = useSession()
  const [isStarring, setIsStarring] = useState(false)

  const starMeme = useCallback(async () => {
    setIsStarring(true)
    try {
      await axios.patch(
        `/api/internal/v1/result/star?resultId=${resultId}`,
        {},
        {
          headers: {
            token: session.access_token,
          },
        }
      )

      callback()
      toast.success('Meme starred successfully.')
    } catch (error) {
      toast.error('Failed to star meme. Please try again later.')
    } finally {
      setIsStarring(false)
    }
  }, [resultId, session])

  return (
    <Button
      size='sm'
      variant='outline'
      disabled={isStarring}
      onClick={starMeme}
    >
      {isStarring ? (
        <Loader2 className='mr-2 h-4 w-4 animate-spin' />
      ) : (
        <Star className='mr-2 h-4 w-4' />
      )}
      Star
    </Button>
  )
}

export { StarButton }
