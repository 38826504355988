// React
import React, { useCallback, useState } from 'react'

// Components
import { Button } from '@/components/ui/button'

// Utils
import { useSession } from '@/utils/auth/user-context'

// External
import axios from 'axios'
import toast from 'react-hot-toast'
import { Loader2, StarOff } from 'lucide-react'

type UnStarButtonProps = {
  resultId: number
  callback: () => void
}

const UnStarButton: React.FC<UnStarButtonProps> = ({ resultId, callback }) => {
  const session = useSession()
  const [isUnStarring, setIsUnStarring] = useState(false)

  const unStarMeme = useCallback(async () => {
    setIsUnStarring(true)
    try {
      await axios.patch(
        `/api/internal/v1/result/unstar?resultId=${resultId}`,
        {},
        {
          headers: {
            token: session.access_token,
          },
        }
      )

      callback()
      toast.success('Meme unstarred successfully.')
    } catch (error) {
      toast.error('Failed to unstar meme. Please try again later.')
    } finally {
      setIsUnStarring(false)
    }
  }, [resultId, session])

  return (
    <Button
      size='sm'
      variant='outline'
      disabled={isUnStarring}
      onClick={unStarMeme}
    >
      {isUnStarring ? (
        <Loader2 className='mr-2 h-4 w-4 animate-spin' />
      ) : (
        <StarOff className='mr-2 h-4 w-4' />
      )}
      Unstar
    </Button>
  )
}

export { UnStarButton }
