// Types
import {
  BaseCaption,
  Caption,
  MemeConstants,
  WatermarkConstants,
} from '@supermeme-ai/types'

// External
import * as gifFrames from 'gif-frames'
import { GIFEncoder, quantize, applyPalette } from 'gifenc'

const extractFrames = async (filePath: string) => {
  const frameData = await gifFrames({
    url: filePath,
    frames: 'all',
    outputType: 'canvas',
    cumulative: true,
  })

  return frameData.map((frame) => ({
    imageData: frame.getImage().toDataURL({
      pixelRatio: 2,
      mimeType: 'image/png',
    }),
    imageHeight: frame.frameInfo.height,
    delay: frame.frameInfo.delay * 10,
    disposal: frame.frameInfo.disposal,
  }))
}

const createGIF = async (
  header: BaseCaption,
  footer: BaseCaption,
  captions: Caption[],
  width: number,
  height: number,
  extractedFrames: any[],
  watermark: string
) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d', { willReadFrequently: true })

  canvas.width = width
  canvas.height = height
  canvas.style.width = '250px'
  canvas.style.height = 'auto'

  const gif = GIFEncoder()

  for (let iterator = 0; iterator < extractedFrames.length; iterator++) {
    const render = sketch()
    await new Promise<void>((resolve, reject) => {
      const imgSrc = extractedFrames[iterator].imageData
      const imageHeight = extractedFrames[iterator].imageHeight
      const img = new Image(width, height)
      img.onerror = reject
      img.onload = () => {
        render({
          context,
          width,
          height,
          captions,
          header,
          footer,
          img,
          imageHeight,
          watermark,
        })
        resolve()
      }

      img.src = imgSrc
    })

    // Get RGBA data from canvas
    const data = context.getImageData(0, 0, width, height).data

    // Choose a pixel format: rgba4444, rgb444, rgb565
    const format = 'rgb444'

    // If necessary, quantize your colors to a reduced palette
    const palette = quantize(data, 256, { format })

    // Apply palette to RGBA data to get an indexed bitmap
    const index = applyPalette(data, palette, format)

    // Write frame into GIF
    const delay = extractedFrames[iterator].delay
    gif.writeFrame(index, width, height, { palette, delay })

    // Wait a tick so that we don't lock up browser
    await new Promise((resolve) => setTimeout(resolve, 0))
  }

  // Finalize stream
  gif.finish()

  // Get a direct typed array view into the buffer to avoid copying it
  const buffer = gif.bytesView()
  return buffer
}

function sketch() {
  return ({
    context,
    width,
    height,
    captions,
    header,
    footer,
    img,
    imageHeight,
    watermark,
  }) => {
    context.clearRect(0, 0, width, height)
    context.fillStyle = 'white'
    context.fillRect(0, 0, width, height)

    let headerHeight = 0
    if (header && header?.text) {
      headerHeight = drawHeader(context, width, header)
    }

    drawImage(context, width, height, img, headerHeight)

    if (captions && captions.length > 0) {
      drawCaptions(context, captions)
    }

    let footerHeight = 0
    if (footer && footer?.text) {
      const startY =
        imageHeight +
        headerHeight +
        (footer?.fontSize ?? MemeConstants.headerFooter.initialFontSize) +
        8 // font size + padding

      footerHeight = drawFooter(context, width, startY, footer)
    }

    drawWatermark(context, width, height, footerHeight, watermark)
  }

  function drawImage(
    context: CanvasRenderingContext2D,
    width: number,
    height: number,
    image: HTMLImageElement,
    headerHeight: number
  ) {
    context.save()
    context.drawImage(
      image,
      0,
      0,
      width,
      height,
      0,
      headerHeight,
      width,
      height
    )
    context.restore()
  }

  function drawCaptions(
    context: CanvasRenderingContext2D,
    captions: Caption[]
  ) {
    if (!captions) {
      return
    }

    context.save()
    context.restore()
  }

  function drawHeader(
    context: CanvasRenderingContext2D,
    width: number,
    header: BaseCaption
  ): number {
    let caption = header?.text
    if (!caption) {
      return
    }

    const font = `bold ${header?.fontSize ?? MemeConstants.headerFooter.initialFontSize}px ${header?.fontFamily ?? MemeConstants.headerFooter.fontFamily}`
    const fillStyle = header?.fontColor ?? MemeConstants.headerFooter.fontColor

    context.save()
    context.font = font
    context.textAlign = 'left'
    context.fillStyle = fillStyle
    context.miterLimit = 1

    const lineHeight = MemeConstants.headerFooter.getLineHeight(
      header?.fontSize ?? MemeConstants.headerFooter.initialFontSize
    )
    const wrappedTopText = wrapText(
      context,
      caption,
      8,
      (header?.fontSize ?? MemeConstants.headerFooter.initialFontSize) + 8, // font size + padding
      width - 16,
      lineHeight
    )

    wrappedTopText.forEach((item) => {
      context.fillText(item[0], item[1], item[2])
    })

    context.restore()
    return wrappedTopText.length * lineHeight + 16
  }

  function drawFooter(
    context: CanvasRenderingContext2D,
    width: number,
    startY: number,
    footer: BaseCaption
  ): number {
    let caption = footer?.text
    if (!caption) {
      return
    }

    const font = `bold ${footer?.fontSize ?? MemeConstants.headerFooter.initialFontSize}px ${footer?.fontFamily ?? MemeConstants.headerFooter.fontFamily}`
    const fillStyle = footer?.fontColor ?? MemeConstants.headerFooter.fontColor

    context.save()
    context.font = font
    context.textAlign = 'left'
    context.fillStyle = fillStyle
    context.miterLimit = 1

    const lineHeight = MemeConstants.headerFooter.getLineHeight(
      footer?.fontSize ?? MemeConstants.headerFooter.initialFontSize
    )
    const wrappedTopText = wrapText(
      context,
      caption,
      8,
      startY,
      width - 16,
      lineHeight
    )

    wrappedTopText.forEach((item) => {
      context.fillText(item[0], item[1], item[2])
    })

    context.restore()
    return wrappedTopText.length * lineHeight + 16
  }

  function drawWatermark(
    context: CanvasRenderingContext2D,
    imageWidth: number,
    imageHeight: number,
    footerHeight: number,
    watermarkText: string
  ) {
    context.save()
    context.font = WatermarkConstants.font
    context.fillStyle = WatermarkConstants.fillStyle
    context.lineWidth = WatermarkConstants.lineWidth
    context.strokeStyle = WatermarkConstants.strokeStyle
    context.textAlign = WatermarkConstants.textAlign as CanvasTextAlign
    context.filter = WatermarkConstants.shadow
    context.miterLimit = WatermarkConstants.miterLimit

    const { width: watermarkTextWidth } = context.measureText(watermarkText)

    const watermarkX = imageWidth - watermarkTextWidth / 2 - 5
    const watermarkY = imageHeight - footerHeight - 5
    const watermarkMaxWidth = imageWidth - 10

    context.strokeText(watermarkText, watermarkX, watermarkY, watermarkMaxWidth)

    context.fillText(watermarkText, watermarkX, watermarkY, watermarkMaxWidth)

    context.restore()
  }

  function wrapText(
    ctx: CanvasRenderingContext2D,
    text: string,
    startPositionX: number,
    startPositionY: number,
    maxWidth: number,
    lineHeight: number
  ): any[] {
    // First, start by splitting all of our text into words, but splitting it into an array split by spaces
    let words = text.split(' ')
    let line = '' // This will store the text of the current line
    let testLine = '' // This will store the text when we add a word, to test if it's too long
    let lineArray = [] // This is an array of lines, which the function will return

    // Lets iterate over each word
    for (var n = 0; n < words.length; n++) {
      // Create a test line, and measure it..
      testLine += `${words[n]} `
      let metrics = ctx.measureText(testLine)
      let testWidth = metrics.width
      // If the width of this test line is more than the max width
      if (testWidth > maxWidth && n > 0) {
        // Then the line is finished, push the current line into "lineArray"
        lineArray.push([line, startPositionX, startPositionY])
        // Increase the line height, so a new line is started
        startPositionY += lineHeight
        // Update line and test line to use this word as the first word on the next line
        line = `${words[n]} `
        testLine = `${words[n]} `
      } else {
        // If the test line is still less than the max width, then add the word to the current line
        line += `${words[n]} `
      }
      // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
      if (n === words.length - 1) {
        lineArray.push([line, startPositionX, startPositionY])
      }
    }
    // Return the line array
    return lineArray
  }
}

export const createGIFBlob = async (
  gifPath: string,
  gifWidth: number,
  gifHeight: number,
  watermark: string,
  header?: BaseCaption,
  footer?: BaseCaption,
  captions?: Caption[]
): Promise<Blob> => {
  const extractedFrames = await extractFrames(gifPath)
  const gifBuffer = await createGIF(
    header,
    footer,
    captions,
    gifWidth,
    gifHeight,
    extractedFrames,
    watermark
  )

  return new Blob([gifBuffer], { type: 'image/gif' })
}
