// React
import React from 'react'

// Types
import { BaseCaption, MemeConstants } from '@supermeme-ai/types'

type EditableMemeProps = {
  src: string
  header?: BaseCaption
  footer?: BaseCaption
  watermark: string
  maxDimension: number
  imageWidth: number
  imageHeight: number
}

const EditableGif: React.FC<EditableMemeProps> = ({
  src,
  header,
  footer,
  watermark,
  maxDimension,
  imageWidth,
  imageHeight,
}) => {
  return (
    <div
      className='relative mx-auto bg-white'
      style={{
        width: `${imageWidth > maxDimension ? maxDimension : imageWidth}px`,
      }}
    >
      {header && header?.text && (
        <p
          className='w-full bg-white p-2 font-bold'
          style={{
            color: header?.fontColor ?? MemeConstants.headerFooter.fontColor,
            fontFamily:
              header?.fontFamily ?? MemeConstants.headerFooter.fontFamily,
            fontSize:
              header?.fontSize ?? MemeConstants.headerFooter.initialFontSize,
            lineHeight: `${MemeConstants.headerFooter.getLineHeight(header?.fontSize ?? MemeConstants.headerFooter.initialFontSize)}px`,
          }}
        >
          {header?.text}
        </p>
      )}

      <div
        className='relative'
        style={{
          height: `${imageHeight}px`,
          width: `${imageWidth}px`,
        }}
      >
        <img src={src} height={`${imageHeight}px`} width={`${imageWidth}px`} />
        {watermark && (
          <span
            className='absolute bottom-1 right-1 text-white drop-shadow-[0_0.5px_3px_rgba(0,0,0,1)]'
            style={{
              font: 'bold 10px Arial Black, sans serif',
              textShadow:
                '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
              strokeWidth: '1px',
            }}
          >
            {watermark}
          </span>
        )}
      </div>

      {footer && footer?.text && (
        <p
          className='w-full bg-white p-2 font-bold'
          style={{
            color: footer?.fontColor ?? MemeConstants.headerFooter.fontColor,
            fontFamily:
              footer?.fontFamily ?? MemeConstants.headerFooter.fontFamily,
            fontSize:
              footer?.fontSize ?? MemeConstants.headerFooter.initialFontSize,
            lineHeight: `${MemeConstants.headerFooter.getLineHeight(footer?.fontSize ?? MemeConstants.headerFooter.initialFontSize)}px`,
          }}
        >
          {footer?.text}
        </p>
      )}
    </div>
  )
}

export { EditableGif }
