import React from 'react'
import { Button } from '@/components/ui/button'
import { Loader2, Share } from 'lucide-react'

type ShareButtonProps = {
  isSharing?: boolean
  onClick: () => void
}

const ShareButton: React.FC<ShareButtonProps> = ({
  onClick,
  isSharing = false,
}) => {
  return (
    <Button size='sm' variant='outline' disabled={isSharing} onClick={onClick}>
      {isSharing ? (
        <Loader2 className='mr-2 h-4 w-4 animate-spin' />
      ) : (
        <Share className='mr-2 h-4 w-4' />
      )}
      Share
    </Button>
  )
}

export { ShareButton }
