import React from 'react'

type AaTextProps = {
  className?: string
}

const AaText: React.FC<AaTextProps> = ({ className }) => {
  return (
    <svg
      viewBox='0 0 256 256'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect fill='none' height='256' width='256' />
      <polyline
        fill='none'
        points='152 192 80 56 8 192'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='8'
      />
      <ellipse
        cx='208'
        cy='166.9'
        fill='none'
        rx='32'
        ry='28'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='8'
      />
      <path
        d='M184,112.4a34.1,34.1,0,0,1,24-9.5c17.7,0,32,12.5,32,28V192'
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='8'
      />
      <line
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='8'
        x1='130.8'
        x2='29.2'
        y1='152'
        y2='152'
      />
    </svg>
  )
}

export default AaText
