// Types
import { BaseCaption, Caption, Result } from '@supermeme-ai/types'

// External
import axios from 'axios'

export const createMemeResult = async (
  accessToken: string,
  imageName: string,
  captions: Caption[],
  header?: BaseCaption,
  footer?: BaseCaption,
  width?: number,
  height?: number
): Promise<Result> => {
  const response = await axios.post<{ result: Result }>(
    `/api/result`,
    {
      imageName,
      captions,
      header,
      footer,
      width,
      height,
    },
    {
      headers: {
        token: accessToken,
      },
    }
  )

  return response.data.result
}
