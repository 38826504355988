// External
import { saveAs } from 'file-saver'

export const downloadGIF = async (blob: Blob) => {
  saveAs(
    blob,
    `supermeme_${new Date().getHours()}h${new Date().getMinutes()}_${new Date().getSeconds()}.gif`
  )
}

export const getFileNameFromUrl = (url: string): string => {
  const imageName = url.split('/').pop().split('#')[0].split('?')[0]
  return imageName
}
