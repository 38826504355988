import React from 'react'
import { Button } from '@/components/ui/button'
import { Copy, Loader2 } from 'lucide-react'

type CopyButtonProps = {
  isCopying?: boolean
  onClick: () => void
}

const CopyButton: React.FC<CopyButtonProps> = ({
  onClick,
  isCopying = false,
}) => {
  return (
    <Button size='sm' variant='outline' disabled={isCopying} onClick={onClick}>
      {isCopying ? (
        <Loader2 className='mr-2 h-4 w-4 animate-spin' />
      ) : (
        <Copy className='mr-2 h-4 w-4' />
      )}
      Copy
    </Button>
  )
}

export { CopyButton }
