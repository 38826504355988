// React
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

// Next
import dynamic from 'next/dynamic'

// Utils
import { useSession } from '@/utils/auth/user-context'
import { saveMemeResult } from '@/utils/ui/api-requests/save-meme-result'
import { createMemeResult } from '@/utils/ui/api-requests/create-meme-result'
import { downloadGIF } from '@/utils/ui/meme/file'
import { createGIFBlob } from '@/utils/meme/gifs'

// Components
import { Button } from '@/components/ui/button'
import { ImageDownloadButton } from '@/components/memes/actions/image-download-button'
import { GifDownloadButton } from '@/components/memes/actions/gif-download-button'
import { CopyButton } from '@/components/memes/actions/copy-button'
import { ShareButton } from '@/components/memes/actions/share-button'
import { StarButton } from '@/components/memes/actions/star-button'
import { UnStarButton } from '@/components/memes/actions/unstar-button'
import AdvancedTextEditor from '@/components/blocks/advanced-text-editor'
import { EditableGif } from '@/components/memes/templates/editable-gif'

const EditableMeme = dynamic(
  () => import('@/components/memes/templates/editable-meme'),
  { ssr: false }
)

const EditableMemeMobile = dynamic(
  () => import('@/components/memes/templates/editable-meme-mobile'),
  { ssr: false }
)

// Types
import {
  BaseCaption,
  Caption,
  MemeConstants,
  MemeFormat,
} from '@supermeme-ai/types'

// External
import { isMobile, isSafari } from 'react-device-detect'
import { toast } from 'react-hot-toast'
import * as Sentry from '@sentry/browser'
import { Plus, X } from 'lucide-react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { useWindowSize } from 'react-use'

type MemeEditorProps = {
  captions: Caption[]
  setCaptions: Dispatch<SetStateAction<Caption[]>>
  resultId: number
  setResultId: Dispatch<SetStateAction<number>>
  watermark: string
  maxDimension: number
  isDesktop: boolean
  // new header and footer
  header?: BaseCaption
  setHeader?: Dispatch<SetStateAction<BaseCaption>>
  footer?: BaseCaption
  setFooter?: Dispatch<SetStateAction<BaseCaption>>
  // configurable properties
  imageSrc?: string
  memeFormat?: MemeFormat
  imageWidth?: number
  imageHeight?: number
  // for starring memes
  isStarredMeme: boolean
  starUnstarCallback: () => void
}

const areCaptionsEqual = (prevCaptions: Caption[], newCaptions: Caption[]) => {
  if (!prevCaptions || !newCaptions) return false

  if (prevCaptions.length !== newCaptions.length) return false

  for (let i = 0; i < prevCaptions.length; i++) {
    if (!isEqual(prevCaptions[i], newCaptions[i])) return false
  }

  return true
}

const autoSaveDebounceTime = 1000 // 1 second debounce time

const debouncedSave = debounce(async (saveFunction) => {
  await saveFunction()
}, autoSaveDebounceTime)

const MemeEditor: React.FC<MemeEditorProps> = ({
  captions,
  setCaptions,
  resultId,
  setResultId,
  watermark,
  maxDimension,
  isDesktop,
  // new header and footer
  header,
  setHeader,
  footer,
  setFooter,
  // configurable properties
  imageSrc,
  memeFormat = 'image',
  imageWidth,
  imageHeight,
  // for starring memes
  isStarredMeme = false,
  starUnstarCallback,
}) => {
  const session = useSession()
  const [isSaving, setIsSaving] = useState(false)
  const [isSharing, setIsSharing] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isCopying, setIsCopying] = useState(false)

  const [showHeaderInput, setShowHeaderInput] = useState(!!header?.text)
  const [showFooterInput, setShowFooterInput] = useState(!!footer?.text)

  const [downloadImageAction, setDownloadImageAction] = useState<
    'facebook' | 'instagram' | 'linkedin' | 'x' | 'default' | null
  >(null)
  const [copyImageAction, setCopyImageAction] = useState(false)
  const [shareImageAction, setShareImageAction] = useState(false)

  const prevCaptions = useRef(captions)
  const prevHeader = useRef(header)
  const prevFooter = useRef(footer)

  const elementId = `meme-container-preview-${resultId}`
  const downloadRef = useRef(null)

  const { width: screenWidth } = useWindowSize()

  const saveFunction = useCallback(async () => {
    setIsSaving(true)
    try {
      if (resultId === 0) {
        const newResult = await createMemeResult(
          session?.access_token,
          imageSrc,
          captions,
          header,
          footer,
          imageWidth,
          imageHeight
        )

        // Update the resultId if it was 0
        setResultId(newResult.id)
      } else {
        await saveMemeResult(
          session?.access_token,
          resultId,
          captions,
          header,
          footer
        )
      }
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      setIsSaving(false)
    }
  }, [
    session?.access_token,
    imageSrc,
    captions,
    header,
    footer,
    imageWidth,
    imageHeight,
    resultId,
    setResultId,
  ])

  const autoSave = useCallback(() => {
    debouncedSave(saveFunction)
  }, [saveFunction])

  // Trigger auto-save when captions, header, or footer change
  const checkAndTriggerAutoSave = useCallback(() => {
    if (
      !areCaptionsEqual(prevCaptions.current, captions) ||
      !isEqual(header, prevHeader.current) ||
      !isEqual(footer, prevFooter.current)
    ) {
      autoSave()
      prevCaptions.current = JSON.parse(JSON.stringify(captions)) // Deep clone
      prevHeader.current = header
      prevFooter.current = footer
    }
  }, [captions, header, footer, autoSave])

  useEffect(() => {
    checkAndTriggerAutoSave()
  }, [captions, header, footer, checkAndTriggerAutoSave])

  const updateCaptions = useCallback(
    (newCaptions: Caption[]) => {
      setCaptions(JSON.parse(JSON.stringify(newCaptions)))
      // The useEffect will trigger checkAndTriggerAutoSave
    },
    [setCaptions]
  )

  const updateHeader = useCallback(
    (newHeader: BaseCaption) => {
      setHeader(newHeader)
      // No need to call autoSave() here, it will be triggered by the useEffect
    },
    [setHeader]
  )

  const updateFooter = useCallback(
    (newFooter: BaseCaption) => {
      setFooter(newFooter)
      // No need to call autoSave() here, it will be triggered by the useEffect
    },
    [setFooter]
  )

  const downloadMemeFile = useCallback(
    async (format: 'facebook' | 'instagram' | 'linkedin' | 'x' | 'default') => {
      try {
        setIsDownloading(true)
        setDownloadImageAction(format)

        if (resultId === 0) {
          await createMemeResult(
            session?.access_token,
            imageSrc,
            captions,
            header,
            footer,
            imageWidth,
            imageHeight
          )
        } else {
          await saveMemeResult(
            session?.access_token,
            resultId,
            captions,
            header,
            footer
          )
        }

        setDownloadImageAction(null)
      } catch (e) {
        Sentry.captureException(e)
        toast.error(
          'An error occurred while trying to download the meme. Please try again.'
        )
      } finally {
        setIsDownloading(false)
      }
    },
    [imageSrc, watermark, captions, header, footer]
  )

  const downloadGifFile = useCallback(async () => {
    try {
      setIsDownloading(true)
      const data = await createGIFBlob(
        imageSrc,
        downloadRef.current.clientWidth,
        downloadRef.current.clientHeight,
        watermark,
        header,
        footer,
        captions
      )

      if (!data) {
        throw new Error('Failed to get blob')
      }

      downloadGIF(data)

      if (resultId === 0) {
        await createMemeResult(
          session?.access_token,
          imageSrc,
          captions,
          header,
          footer,
          imageWidth,
          imageHeight
        )
      } else {
        await saveMemeResult(
          session?.access_token,
          resultId,
          captions,
          header,
          footer
        )
      }
    } catch (e) {
      Sentry.captureException(e)
      toast.error(
        'An error occurred while trying to download the meme. Please try again.'
      )
    } finally {
      setIsDownloading(false)
    }
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const copyMemeFile = useCallback(async () => {
    try {
      setIsCopying(true)
      setCopyImageAction(true)
      if (resultId === 0) {
        await createMemeResult(
          session?.access_token,
          imageSrc,
          captions,
          header,
          footer,
          imageWidth,
          imageHeight
        )
      } else {
        await saveMemeResult(
          session?.access_token,
          resultId,
          captions,
          header,
          footer
        )
      }
      toast.success('Meme copied to the clipboard.')
      setCopyImageAction(false)
    } catch (e) {
      Sentry.captureException(e)
      toast.error(
        'An error occurred while trying to copy the meme. Please try again.'
      )
    } finally {
      setIsCopying(false)
    }
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const shareMemeFile = useCallback(async () => {
    setShareImageAction(true)
    if (resultId === 0) {
      await createMemeResult(
        session?.access_token,
        imageSrc,
        captions,
        header,
        footer,
        imageWidth,
        imageHeight
      )
    } else {
      await saveMemeResult(
        session?.access_token,
        resultId,
        captions,
        header,
        footer
      )
    }
    setShareImageAction(false)
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const shareGifFile = useCallback(async () => {
    setIsSharing(true)
    const data = await createGIFBlob(
      imageSrc,
      downloadRef.current.clientWidth,
      downloadRef.current.clientHeight,
      watermark,
      header,
      footer,
      captions
    )

    if (!data) {
      throw new Error('Failed to get blob')
    }

    const file = new File([data], 'Supermeme.gif', { type: 'image/gif' })

    if (!navigator.share || navigator.canShare({ files: [file] }) === false) {
      await downloadGIF(data)
      setIsSharing(false)
      return
    }

    return navigator
      .share({
        files: [file],
      })
      .then(() => {
        toast.success('Meme shared successfully.')
      })
      .catch((error) => {
        Sentry.captureException(error)
        toast.error('Sharing was cancelled.')
      })
      .finally(() => {
        setIsSharing(false)
      })
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  return (
    <div
      id='meme-editor-v2'
      className='mt-2 grid w-full max-w-7xl grid-cols-1 md:mt-0 lg:grid-cols-12 lg:gap-4'
    >
      <div className='order-last col-span-5 mb-10 flex w-full justify-center lg:order-none'>
        <div className='h-full w-full'>
          <div className='w-full px-0 py-4 md:px-4'>
            <div className='space-y-8'>
              <div className='w-full space-y-2'>
                {showHeaderInput && (
                  <div className='w-full'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Header caption
                    </label>
                    <div className='mt-[2px] flex rounded-md shadow-sm'>
                      <div className='relative flex w-full flex-grow items-stretch focus-within:z-10'>
                        <input
                          type='text'
                          value={header?.text}
                          onChange={(e) =>
                            updateHeader({ ...header, text: e.target.value })
                          }
                          className='block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        />
                      </div>

                      <AdvancedTextEditor
                        fontFamily={header.fontFamily}
                        onFontFamilyChange={(fontFamily) => {
                          const headerCopy = { ...header }
                          headerCopy.fontFamily = fontFamily
                          updateHeader(headerCopy)
                        }}
                        fontSize={header.fontSize}
                        onFontSizeChange={(fontSize) => {
                          const headerCopy = { ...header }
                          headerCopy.fontSize = fontSize
                          updateHeader(headerCopy)
                        }}
                        fontColor={header.fontColor ?? '#000000'}
                        onFontColorChange={(fontColor) => {
                          const headerCopy = { ...header }
                          headerCopy.fontColor = fontColor
                          updateHeader(headerCopy)
                        }}
                        showBorderColorConfig={false}
                      />

                      <button
                        type='button'
                        className='relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none'
                        onClick={() => {
                          setShowHeaderInput(false)
                          updateHeader(null)
                        }}
                      >
                        <X
                          className='h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </div>
                )}

                {showFooterInput && (
                  <div className='w-full'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Footer caption
                    </label>
                    <div className='mt-[2px] flex rounded-md shadow-sm'>
                      <div className='relative flex w-full flex-grow items-stretch focus-within:z-10'>
                        <input
                          type='text'
                          value={footer?.text}
                          onChange={(e) =>
                            updateFooter({ ...footer, text: e.target.value })
                          }
                          className='block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        />
                      </div>

                      <AdvancedTextEditor
                        fontFamily={footer.fontFamily}
                        onFontFamilyChange={(fontFamily) => {
                          const footerCopy = { ...footer }
                          footerCopy.fontFamily = fontFamily
                          updateFooter(footerCopy)
                        }}
                        fontSize={footer.fontSize}
                        onFontSizeChange={(fontSize) => {
                          const footerCopy = { ...footer }
                          footerCopy.fontSize = fontSize
                          updateFooter(footerCopy)
                        }}
                        fontColor={footer.fontColor ?? '#000000'}
                        onFontColorChange={(fontColor) => {
                          const footerCopy = { ...footer }
                          footerCopy.fontColor = fontColor
                          updateFooter(footerCopy)
                        }}
                        showBorderColorConfig={false}
                      />

                      <button
                        type='button'
                        className='relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none'
                        onClick={() => {
                          setShowFooterInput(false)
                          updateFooter(null)
                        }}
                      >
                        <X
                          className='h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </div>
                )}

                {captions &&
                  captions.map((caption, i) => (
                    <div className='w-full' key={i}>
                      <label className='block text-sm font-medium text-gray-700'>
                        Caption {i + 1}
                      </label>
                      <div
                        key={i}
                        className='mt-[2px] flex rounded-md shadow-sm'
                      >
                        <div className='relative flex w-full flex-grow items-stretch focus-within:z-10'>
                          <input
                            type='text'
                            value={caption.text}
                            className='block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                            onChange={(e) => {
                              const newCaptions = JSON.parse(
                                JSON.stringify(captions)
                              ) // Deep clone
                              newCaptions[i].text = e.target.value
                              updateCaptions(newCaptions)
                            }}
                          />
                        </div>

                        <AdvancedTextEditor
                          fontFamily={caption.fontFamily}
                          onFontFamilyChange={(fontFamily) => {
                            const newCaptions = JSON.parse(
                              JSON.stringify(captions)
                            ) // Deep clone
                            newCaptions[i].fontFamily = fontFamily
                            updateCaptions(newCaptions)
                          }}
                          fontSize={caption.fontSize}
                          onFontSizeChange={(fontSize) => {
                            const newCaptions = JSON.parse(
                              JSON.stringify(captions)
                            ) // Deep clone
                            newCaptions[i].fontSize = fontSize
                            updateCaptions(newCaptions)
                          }}
                          fontColor={caption.fontColor ?? '#FFFFFF'}
                          onFontColorChange={(fontColor) => {
                            const newCaptions = JSON.parse(
                              JSON.stringify(captions)
                            ) // Deep clone
                            newCaptions[i].fontColor = fontColor
                            updateCaptions(newCaptions)
                          }}
                          borderColor={caption.borderColor ?? '#000000'}
                          onBorderColorChange={(borderColor) => {
                            const newCaptions = JSON.parse(
                              JSON.stringify(captions)
                            ) // Deep clone
                            newCaptions[i].borderColor = borderColor
                            updateCaptions(newCaptions)
                          }}
                        />

                        <button
                          type='button'
                          className='relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none'
                          onClick={() => {
                            const newCaptions = JSON.parse(
                              JSON.stringify(captions)
                            ) // Deep clone
                            newCaptions.splice(i, 1)
                            updateCaptions(newCaptions)
                          }}
                        >
                          <X
                            className='h-5 w-5 text-gray-400'
                            aria-hidden='true'
                          />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>

              <div className='flex flex-row items-center justify-start space-x-2'>
                {memeFormat === 'image' && (
                  <Button
                    className='flex-1'
                    onClick={() => {
                      const currentCaptions = captions || []
                      const newCaptions = JSON.parse(
                        JSON.stringify(currentCaptions)
                      ) // Deep clone
                      newCaptions.push({
                        fontSize: MemeConstants.initialFontSize,
                        text: 'Meme text goes here',
                        width: imageWidth,
                        x: 0,
                        y: 30,
                      })
                      updateCaptions(newCaptions)
                    }}
                  >
                    <Plus className='mr-2 h-4 w-4' aria-hidden='true' />
                    <span>New text</span>
                  </Button>
                )}

                <Button
                  disabled={showHeaderInput}
                  className='flex-1'
                  onClick={() => {
                    setShowHeaderInput(true)
                    updateHeader({
                      text: 'Place your text here',
                      fontSize: MemeConstants.headerFooter.initialFontSize,
                      fontColor: MemeConstants.headerFooter.fontColor,
                      fontFamily: MemeConstants.headerFooter.fontFamily,
                    })
                  }}
                >
                  <Plus className='mr-2 h-4 w-4' aria-hidden='true' />
                  <span>Header</span>
                </Button>

                <Button
                  disabled={showFooterInput}
                  className='flex-1'
                  onClick={() => {
                    setShowFooterInput(true)
                    updateFooter({
                      text: 'Place your text here',
                      fontSize: MemeConstants.headerFooter.initialFontSize,
                      fontColor: MemeConstants.headerFooter.fontColor,
                      fontFamily: MemeConstants.headerFooter.fontFamily,
                    })
                  }}
                >
                  <Plus className='mr-2 h-4 w-4' aria-hidden='true' />
                  <span>Footer</span>
                </Button>
              </div>

              {isDesktop && memeFormat !== 'gif' && (
                <div className='flex items-center justify-center'>
                  <span className='text-xs text-gray-600'>
                    Drag and drop meme text to change text position and size
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='relative order-first col-span-7 flex w-full justify-center lg:order-none'>
        <div className='space-y-4'>
          <div
            id={elementId}
            className={`relative flex border-gray-300 ${showFooterInput ? 'border-b' : ''} ${showHeaderInput ? 'border-t' : ''}`}
            ref={downloadRef}
            style={{ width: `${isMobile ? screenWidth - 8 : imageWidth}px` }}
          >
            {memeFormat === 'image' && isDesktop && (
              <EditableMeme
                src={imageSrc}
                captions={captions}
                setCaptions={setCaptions}
                watermark={watermark}
                maxDimension={maxDimension}
                header={header}
                footer={footer}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                downloadImageAction={downloadImageAction}
                copyImageAction={copyImageAction}
                shareImageAction={shareImageAction}
              />
            )}

            {memeFormat === 'image' && !isDesktop && (
              <EditableMemeMobile
                src={imageSrc}
                captions={captions}
                setCaptions={setCaptions}
                watermark={watermark}
                maxDimension={maxDimension}
                header={header}
                footer={footer}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                downloadImageAction={downloadImageAction}
                copyImageAction={copyImageAction}
                shareImageAction={shareImageAction}
              />
            )}

            {memeFormat === 'gif' && (
              <EditableGif
                src={imageSrc}
                header={header}
                footer={footer}
                watermark={watermark}
                maxDimension={maxDimension}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
              />
            )}
          </div>

          <div className='flex items-center justify-center space-x-2'>
            {isDesktop && memeFormat === 'image' && (
              <ImageDownloadButton
                isDownloading={isDownloading}
                facebookDownload={() => downloadMemeFile('facebook')}
                instagramDownload={() => downloadMemeFile('instagram')}
                linkedinDownload={() => downloadMemeFile('linkedin')}
                xDownload={() => downloadMemeFile('x')}
                defaultDownload={() => downloadMemeFile('default')}
              />
            )}
            {isDesktop && memeFormat === 'gif' && (
              <GifDownloadButton
                isDownloading={isDownloading}
                download={() => downloadGifFile()}
              />
            )}
            {isDesktop && !isSafari && memeFormat !== 'gif' && (
              <CopyButton isCopying={isCopying} onClick={copyMemeFile} />
            )}

            {!!isStarredMeme ? (
              <UnStarButton resultId={resultId} callback={starUnstarCallback} />
            ) : (
              <StarButton resultId={resultId} callback={starUnstarCallback} />
            )}

            {!isDesktop && memeFormat === 'image' && (
              <ShareButton isSharing={isSharing} onClick={shareMemeFile} />
            )}

            {!isDesktop && memeFormat === 'gif' && (
              <ShareButton isSharing={isSharing} onClick={shareGifFile} />
            )}
          </div>

          <div className='text-center text-xs italic text-gray-500'>
            {isSaving && 'Saving...'}
            {!isSaving &&
              `Auto-saved at ${new Date().toLocaleTimeString(
                navigator.language,
                {
                  hour: 'numeric',
                  minute: '2-digit',
                }
              )}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export { MemeEditor }
