import { Button } from '@/components/ui/button'
import { Download, Loader2 } from 'lucide-react'

type GifDownloadButtonProps = {
  isDownloading?: boolean
  download: () => void
}

const GifDownloadButton: React.FC<GifDownloadButtonProps> = ({
  download,
  isDownloading = false,
}) => {
  return (
    <Button
      size='sm'
      variant='outline'
      disabled={isDownloading}
      onClick={download}
    >
      {isDownloading ? (
        <Loader2 className='mr-2 h-4 w-4 animate-spin text-indigo-600' />
      ) : (
        <Download className='mr-2 h-4 w-4' />
      )}
      Download
    </Button>
  )
}

export { GifDownloadButton }
