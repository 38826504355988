import React, { useState } from 'react'
import {
  ChevronDown,
  Download,
  Facebook,
  Info,
  Instagram,
  Linkedin,
  Loader2,
  Twitter,
} from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

type ImageDownloadButtonProps = {
  isDownloading?: boolean
  facebookDownload: () => void
  instagramDownload: () => void
  linkedinDownload: () => void
  xDownload: () => void
  defaultDownload: () => void
}

const ImageDownloadButton: React.FC<ImageDownloadButtonProps> = ({
  facebookDownload,
  instagramDownload,
  linkedinDownload,
  xDownload,
  defaultDownload,
  isDownloading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className='flex items-center justify-center'>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Button
              size='sm'
              variant='outline'
              className='rounded-l-md rounded-r-none border border-r-0 border-indigo-600'
              disabled={isDownloading}
              onClick={() => {
                defaultDownload()
                setIsOpen(false)
              }}
            >
              {isDownloading ? (
                <Loader2 className='mr-2 h-4 w-4 animate-spin text-indigo-600' />
              ) : (
                <Download className='mr-2 h-4 w-4' />
              )}
              Download
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className='w-64'>
              <Info className='mb-1 mr-1 inline h-3 w-3' />
              Click the dropdown to choose a target platform for your meme.
              Choosing a platform will resize the image to the platform&apos;s
              recommended size (e.g. 1080x1080 for Instagram)
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button
            size='smIcon'
            variant='outline'
            className='rounded-l-none rounded-r-md border border-l border-indigo-600 px-2'
            disabled={isDownloading}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ChevronDown className='h-6 w-6' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-72'>
          <div className='grid gap-2'>
            <label className='text-sm font-bold'>Choose target platform</label>
            <div className='border-b border-gray-200' />
            <Button
              size='sm'
              variant='ghost'
              className='dark:hover:bg-slate-800 justify-start hover:bg-gray-100'
              onClick={() => {
                facebookDownload()
                setIsOpen(false)
              }}
            >
              <Facebook className='mr-4 h-4 w-4' />
              <span>Facebook (9:16)</span>
            </Button>
            <Button
              size='sm'
              variant='ghost'
              className='dark:hover:bg-slate-800 justify-start hover:bg-gray-100'
              onClick={() => {
                instagramDownload()
                setIsOpen(false)
              }}
            >
              <Instagram className='mr-4 h-4 w-4' />
              <span>Instagram (1:1)</span>
            </Button>
            <Button
              size='sm'
              variant='ghost'
              className='dark:hover:bg-slate-800 justify-start hover:bg-gray-100'
              onClick={() => {
                linkedinDownload()
                setIsOpen(false)
              }}
            >
              <Linkedin className='mr-4 h-4 w-4' />
              <span>LinkedIn (16:9)</span>
            </Button>
            <Button
              size='sm'
              variant='ghost'
              className='dark:hover:bg-slate-800 justify-start hover:bg-gray-100'
              onClick={() => {
                xDownload()
                setIsOpen(false)
              }}
            >
              <Twitter className='mr-4 h-4 w-4' />
              <span>X (formerly Twitter) (16:9)</span>
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export { ImageDownloadButton }
