// React
import React, { Fragment } from 'react'

// Components
import AaText from '@/components/icons/aa-text'
import DefaultColorBlocks from '@/components/blocks/default-color-blocks'

// Types
import { SupportedFonts } from '@supermeme-ai/types'

// Utils
import { classNames } from '@supermeme-ai/utils'

// External
import { Popover, Transition } from '@headlessui/react'
import { isDesktop } from 'react-device-detect'

type AdvancedTextEditorProps = {
  fontFamily?: string
  onFontFamilyChange: (fontFamily: string) => void
  fontSize?: number
  onFontSizeChange: (fontSize: number) => void
  fontColor?: string
  onFontColorChange: (color: string) => void
  borderColor?: string
  onBorderColorChange?: (borderColor: string) => void
  showBorderColorConfig?: boolean
}

const AdvancedTextEditor: React.FC<AdvancedTextEditorProps> = ({
  fontFamily,
  onFontFamilyChange,
  fontSize,
  onFontSizeChange,
  fontColor,
  onFontColorChange,
  borderColor,
  onBorderColorChange,
  showBorderColorConfig = true,
}) => {
  return (
    <>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button className='relative -ml-px inline-flex h-full w-11 items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'>
              <span className='flex h-full w-full border border-gray-400'>
                <span
                  style={{ backgroundColor: fontColor }}
                  className='w-full'
                ></span>
                {showBorderColorConfig && (
                  <span
                    style={{ backgroundColor: borderColor }}
                    className='w-full'
                  ></span>
                )}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel
                className={classNames(
                  'absolute -top-full right-10 z-10 mr-3 transform rounded-md border border-gray-500 px-4 shadow-xl sm:px-0',
                  showBorderColorConfig ? 'w-[350px]' : 'w-[190px]'
                )}
              >
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div
                    className={classNames(
                      'relative grid gap-12 bg-gray-100 p-8',
                      showBorderColorConfig ? 'grid-cols-2' : 'grid-cols-1'
                    )}
                  >
                    <div className='focus-visible:ring-orange-500 -m-3 flex flex-col items-center justify-center space-y-2 rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-opacity-50'>
                      <div className='w-full'>
                        <label
                          htmlFor='font-color'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Font color
                        </label>
                        <div className='mt-2 flex rounded-md shadow-sm'>
                          <div className='relative flex flex-grow items-stretch focus-within:z-10'>
                            <input
                              type='text'
                              name='font-color'
                              id='font-color'
                              className='block w-full rounded-none rounded-l-md border-0 px-3 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder='Font color'
                              value={fontColor}
                              onChange={(e) =>
                                onFontColorChange(e.target.value)
                              }
                            />
                          </div>
                          <button
                            type='button'
                            className='relative -ml-px inline-flex w-9 items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                            style={{
                              backgroundColor: fontColor,
                            }}
                            onClick={() => {
                              const input =
                                document.getElementById('hidden-font-color')
                              input?.click()
                            }}
                          >
                            <input
                              id='hidden-font-color'
                              type='color'
                              style={{ display: 'none' }}
                              value={fontColor}
                              onChange={(e) =>
                                onFontColorChange(e.target.value)
                              }
                            />
                          </button>
                        </div>
                      </div>
                      <DefaultColorBlocks onClick={onFontColorChange} />
                    </div>

                    {onBorderColorChange && (
                      <div className='focus-visible:ring-orange-500 -m-3 flex flex-col items-center justify-center space-y-2 rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-opacity-50'>
                        <div className='w-full'>
                          <label
                            htmlFor='border-color'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Border color
                          </label>
                          <div className='mt-2 flex rounded-md shadow-sm'>
                            <div className='relative flex flex-grow items-stretch focus-within:z-10'>
                              <input
                                type='text'
                                name='border-color'
                                id='border-color'
                                className='block w-full rounded-none rounded-l-md border-0 px-3 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                placeholder='Border color'
                                value={borderColor}
                                onChange={(e) =>
                                  onBorderColorChange(e.target.value)
                                }
                              />
                            </div>
                            <button
                              type='button'
                              className='relative -ml-px inline-flex w-9 items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                              style={{
                                backgroundColor: borderColor,
                              }}
                              onClick={() => {
                                const input = document.getElementById(
                                  'hidden-border-color'
                                )
                                input?.click()
                              }}
                            >
                              <input
                                id='hidden-border-color'
                                type='color'
                                style={{ display: 'none' }}
                                value={borderColor}
                                onChange={(e) =>
                                  onBorderColorChange(e.target.value)
                                }
                              />
                            </button>
                          </div>
                        </div>
                        <DefaultColorBlocks onClick={onBorderColorChange} />
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button className='relative -ml-px inline-flex h-full items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'>
              <AaText className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -top-full right-10 z-10 mr-3 w-[250px] transform rounded-md border border-gray-500 px-4 shadow-xl sm:px-0'>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative grid grid-cols-1 gap-4 bg-gray-100 p-7'>
                    <div className='focus-visible:ring-orange-500 -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-opacity-50'>
                      <div className='w-full'>
                        <label
                          htmlFor='font-family'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Font family
                        </label>
                        <select
                          id='font-family'
                          name='font-family'
                          className='default-input'
                          onChange={(e) => onFontFamilyChange(e.target.value)}
                          value={fontFamily}
                        >
                          {SupportedFonts(isDesktop).map((item) => (
                            <option
                              key={item.fontFamily}
                              value={item.fontFamily}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className='focus-visible:ring-orange-500 -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-opacity-50'>
                      <div className='w-full'>
                        <label
                          htmlFor='font-size'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Font size
                        </label>
                        <input
                          id='font-size'
                          name='font-size'
                          type='number'
                          placeholder='Font size'
                          className='default-input'
                          onChange={(e) =>
                            onFontSizeChange(parseInt(e.target.value))
                          }
                          value={fontSize}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default AdvancedTextEditor
